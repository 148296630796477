import React from 'react';
import { Box, Grid } from '@material-ui/core';
import ChatRoom from './chatRoom';
import { DraggableCardPile } from './card';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

const useStyles = makeStyles({

});

function Supply(props) {
    let cards = _.chunk(props.supply, 2).map(cardNames => 
        cardNames.length === 1 ? <DraggableCardPile cardName={cardNames[0]} cardData={props.cardData} /> :
        <><DraggableCardPile cardName={cardNames[0]} cardData={props.cardData} />
        <DraggableCardPile cardName={cardNames[1]} cardData={props.cardData} /></>);
    return <div id='supply' style={{display:'grid', overflow: 'scroll'}}>{cards}</div>
}


export default function DominionGameDisplay(props) {
    console.log(props);
    return <div id='container' style={{height: "100%", width: "100%", 
        display: 'grid', gridTemplateColumns: '75% 25%', gridTemplateRows:'75% 25%',
        gridTemplateAreas: ""}}>
        
    </div>
}

/*
<Grid container direction="column" style={{ height: "100vh" }}>
        <Grid item container xs={9}>

                <Grid item xs={2}><Supply supply={props.supply} cardData={props.cardData} />Supply</Grid>

        </Grid>
        <Grid item xs={3}>
            Chat
            <Box display="flex" justifyContent="flex-end" flexDirection="column">
                <ChatRoom msgs={props.msgs} sendMessage={props.sendMessage} />
            </Box>
        </Grid>
    </Grid>*/