import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import './App.css';
import Login from './login';
import Lobby from './lobby';
import { postJSON } from './util';
import { MuiThemeProvider, CssBaseline } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { createMuiTheme } from '@material-ui/core/styles';
import { green, yellow } from '@material-ui/core/colors';

import { withCookies } from 'react-cookie';

import DominionGame from './dominionGame';

// tslint:disable-next-line
import Playground from "./playground";

const theme = createMuiTheme({
  palette: {
    primary: green,
    secondary: yellow
  },
  typography: {
    fontWeightBold: 1000
  }
});

class App extends Component {
  constructor(props) {
    super(props);
    this.hostname = `${window.location.hostname}:${window.location.port}`;
    if (process.env.NODE_ENV === "development")
      this.hostname = "localhost:8080";
    this.lobbyWS = `ws://${this.hostname}/lobbychat`;
    this.gameWS = `ws://${this.hostname}/dominionws`;
    this.state = { username: null, lastError: null };

  }

  loginAttempt(username) {
    postJSON(`http://${this.hostname}/client/pick_username`, { "username": username }).then((resp) =>
      resp.json()).then((data) => {

        if (data.response === 'ok') {
          this.props.cookies.set('username', username, { path: '/' });
          this.setState({ 'username': username, lastError: null });
        }
        else
          this.setState({ lastError: `The username ${username} is in use.` })
      }).catch(err => {
        this.setState({ lastError: "Error connecting to the server!  It may be down.  You can try again." });
      });
  }

  logout() {
    this.setState({ 'username': null });
    this.props.cookies.remove('username');
  }

  componentDidMount() {
    let cookiename = this.props.cookies.get('username');
    if (this.state.username === null && cookiename !== undefined) {
      cookiename = decodeURI(cookiename);
      this.loginAttempt(cookiename);
    }
  }

  redirectToGame(game_id) {
    window.location.assign(`http://${this.hostname}/game/${game_id}`);
  }

  routing() {
    let error = "";
    if (this.state.lastError !== null)
      error = <Alert severity="error">{this.state.lastError}</Alert>
    this.logout = this.logout.bind(this);
    this.redirectToGame = this.redirectToGame.bind(this);
    if (this.state.username === null)
      return <Switch>
        <Route path='/'>
          {error}
          <Login onLoginAttempt={(username) => this.loginAttempt(username)} />
        </Route>
        <Route path='/*'>
          <Redirect to='/' />
        </Route>
      </Switch>
    else
      return <Switch>
        <Route path="/lobby">
          <Lobby
            username={this.state.username}
            handleNewGame={this.redirectToGame}
            lobbyWS={this.lobbyWS}
            logout={this.logout} />
        </Route>
        <Route path='/game/:id' render={(routeProps) => <DominionGame
          username={this.state.username}
          gameSocketAddr={this.gameWS}
          gameId={routeProps.match.params.id} />} />
        <Route path='/'>
          <Redirect to='/lobby' />
        </Route>
      </Switch>
  }

    //  render() {
    //     return <MuiThemeProvider theme={theme}>
    //     <CssBaseline />
    //     <Playground/>
    //     </MuiThemeProvider>
    //  }

 render() {
    return <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {this.routing()}
    </MuiThemeProvider>
  }
}
export default withCookies(App);
