import React from 'react';
import { BrowserRouter} from 'react-router-dom';

import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { CookiesProvider } from 'react-cookie';

//import registerServiceWorker from './registerServiceWorker';


ReactDOM.render(<BrowserRouter basename='/client'><CookiesProvider><App /></CookiesProvider></BrowserRouter>, document.getElementById('root'));
//registerServiceWorker();
 