import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'

import { Typography, Box, Grid, Button } from '@material-ui/core';
import { GameList, SuggestedGameDialog } from './lobbyComponents';
import Alert from '@material-ui/lab/Alert';

import NewGameDrawer from './newGameDrawer';
import SuggestedGameDrawer from './suggestedGameDrawer';
import ChatRoom from './chatRoom';

import _ from 'lodash';

const stateDefault = {
    wsStatus: 'Not Connected',
    msgs: [],
    games: [],
    lobbyOccupants: [],
    newGameDrawer: false,
    suggestedGameInitiator: null,
    suggestedGamePlayers: [],
    suggestedGameId: null,
    acceptedGameId: null,
    acceptedGamePlayers: [],
    lastReject: null,
    documentVisibility: 'visible'
};


export default class Lobby extends Component {

    constructor(props) {
        super(props);
        this.state = { ...stateDefault };
        this.reconnect = null;
    }

    componentDidMount() {
        this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
        document.addEventListener('visibilitychange', this.handleVisibilityChange, false);
        this.original_title = document.title;
        this.socket = new WebSocket(this.props.lobbyWS);
        this.initSocket();
    }

    componentWillUnmount() {
        this.socket.close();
    }

    logout() {
        this.socket.onclose = undefined;
        this.socket.close();
        this.props.logout();
    }

    initSocket() {
        this.socket.onmessage = (msg) => {
            let data = JSON.parse(msg.data);
            if (data.hasOwnProperty('request'))
                this.requestHandler(data);
            else
                this.messageHandler(data);
        };
        this.socket.onerror = (msg) => {
            this.setState({ wsStatus: "Error" });
            this.tryReconnect();
        };
        this.socket.onclose = (msg) => {
            this.setState({ wsStatus: "Closed" });
            this.tryReconnect();
        };
        this.socket.onopen = (msg) => {
            this.setState({ wsStatus: "Connected" })
            clearInterval(this.reconnect);
            this.reconnect = null;
        }
    }

    tryReconnect() {
        if (this.reconnect === null)
            this.reconnect = setInterval(() => {
                console.log("Reconnecting.");
                if (this.socket.readyState === WebSocket.CLOSED) {
                    this.socket = new WebSocket(this.props.lobbyWS);
                    this.initSocket(this.socket)
                }
            }, 10000);
    }

    handleVisibilityChange() {
        this.setState({documentVisibility : document.visibilityState});
    }

    blinkTitleBar() {
        document.title = "☎ " + this.original_title;
        this.setTimeout(() => document.title = this.original_title, 1500);
    }

    requestHandler(req) {
        switch (req.request) {
            case 'login':
                this.socket.send(JSON.stringify({ 'response': 'login', 'username': this.props.username }));
                this.setState({ 'wsStatus': 'Logged In' });
                break;
            case 'suggest_game':
                if (this.state.documentVisibility == 'hidden')
                    this.blinkTitleBar();
                this.setState({
                    suggestedGameId: req.game_id,
                    suggestedGamePlayers: req.players,
                    suggestedGameInitiator: req.initiator,
                    acceptedGamePlayers: [req.initiator]
                });
                if (req.initiator === this.props.username)
                    this.setState({ acceptedGameId: req.game_id });
                break;
            default:
        }
    }

    messageHandler(msg) {
        if (this.state.documentVisibility == 'hidden')
            this.blinkTitleBar();
        if (msg.hasOwnProperty('chat'))
            this.setState(prevState => ({ msgs: prevState.msgs.concat([{ username: msg.username, text: msg.chat }]) }));
        else if (msg.hasOwnProperty('update_gamelist'))
            this.setState({ games: _.zipObject(msg.update_gamelist, msg.playerlist) });
        else if (msg.hasOwnProperty('update_chatlist'))
            this.setState({ lobbyOccupants: msg.update_chatlist });
        else if (msg.hasOwnProperty('begin_new_game'))
            this.props.handleNewGame(msg.begin_new_game);
        else if (msg.hasOwnProperty('suggest_response'))
            this.handlePlayerSuggestedGameResponse(msg)
    }

    handlePlayerSuggestedGameResponse(msg) {
        if (msg.suggest_response === 'accept') {
            this.setState(prevState => this.setState({
                acceptedGamePlayers: [...prevState.acceptedGamePlayers, msg.player]
            }));
        }
        else {
            this.setState({
                suggestedGameId: null,
                acceptedGameId: null,
                lastReject: msg.player
            });
        }
    }

    sendChatMessage(msg) {
        if (msg)
            this.socket.send(JSON.stringify({ username: this.props.username, chat: msg }));
    }

    toggleDrawerState() {
        this.setState(prevState => ({ newGameDrawer: !prevState.newGameDrawer }));
    }

    answerSuggestedGame(answer) {
        this.socket.send(JSON.stringify({
            response: 'suggest_game',
            suggest_game: answer,
            game_id: this.state.suggestedGameId
        }));
        if (answer === "reject") {
            this.setState({ suggestedGameId: null });
        }
        else {
            this.setState({ acceptedGameId: this.state.suggestedGameId });
        }
    }

    onSuggest(players, manualOpts) {
        let gameOptions = [];
        if (players.includes("Robot"))
            gameOptions.push("usebot");

        gameOptions = gameOptions.join(",");
        if (manualOpts.length)
            gameOptions = `${gameOptions},${manualOpts}`;
        let other_players = _.without(players, "Robot", this.props.username);
        this.socket.send(JSON.stringify({
            suggest_game: 'dominion',
            players: other_players,
            game_options: gameOptions
        }));
    }

    render() {
        if (this.props.username === undefined)
            return <Redirect to="/" />

        let error = "";
        if (this.state.wsStatus === "Error")
            error = <Alert severity="error">There is an issue connecting to the lobby, please wait or refresh the page.</Alert>
        else if (this.state.wsStatus === "Closed")
            error = <Alert severity="error">The server has closed the connection (maybe it was restarted?).  Please wait or refresh the page.</Alert>

        let availablePlayers = _.concat(_.without(this.state.lobbyOccupants, this.props.username), "Robot");

        this.onSuggest = this.onSuggest.bind(this);
        this.logout = this.logout.bind(this);

        return <>
            <Box position="static" display="flex" alignItems="center" bgcolor="primary.light" >
                <Box m="auto">
                    {error}
                    <Typography justify="center" variant="h5" style={{ color: 'white' }} gutterBottom>
                        REIGN
                    </Typography>
                </Box>
                <Box alignSelf="right" mr={5}><Button onClick={this.logout}>Change Name</Button></Box>
            </Box>
            <Grid container style={{ 'minHeight': '100%' }} spacing={3}>
                <Grid item xs={9}>
                    <ChatRoom msgs={this.state.msgs} sendMessage={msg => this.sendChatMessage(msg)} />
                </Grid>
                <Grid item xs={3}>
                    <GameList
                        lobbyOccupants={this.state.lobbyOccupants}
                        games={this.state.games}
                        onNewGame={evt => this.toggleDrawerState()} />
                </Grid>
            </Grid>
            <NewGameDrawer
                open={this.state.newGameDrawer}
                availablePlayers={availablePlayers}
                onClose={evt => this.toggleDrawerState()}
                onSuggest={this.onSuggest} />
            <SuggestedGameDialog
                open={this.state.suggestedGameId !== null && this.state.acceptedGameId === null}
                handleClose={e => this.answerSuggestedGame('reject')}
                handleAccept={e => this.answerSuggestedGame('accept')}
                initiator={this.state.suggestedGameInitiator}
                players={this.state.suggestedGamePlayers} />
            <SuggestedGameDrawer
                gameId={this.state.acceptedGameId}
                handleClose={e => this.answerSuggestedGame('reject')}
                allPlayers={this.state.suggestedGamePlayers}
                greenPlayers={this.state.acceptedGamePlayers} />
        </>
    }
}
