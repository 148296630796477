import React, { useState } from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DraggableCore } from 'react-draggable';

const useStyles = makeStyles({
	mediumCard: {
		height: "8vh",
		width: "8.5vh",
		border: "3px solid black",
		borderRadius: "10px",
		padding: "2px",
		fontFamily: '"Fondamento", "sans serif"',
		fontSize: "small",
		display: 'flex',
		flexDirection: "column",

		'& span.cost': {
			float: 'right'
		},
		'&:hover': {
			backgroundColor: "#EFEFEF"
		}
	},
	mediumCardImg: {
		flex: '1',
		minHeight: 0,
		width: 0,
		'& img': {
			height: "100%",
			width:"auto"
		},
	}
});


export default function Card(props) {
	let { cardName, cardData, isChoosable, extraDetails, ...other } = props;
	let myData = cardData[cardName];
	const classes = useStyles();

	return <Paper {...other} className={classes.mediumCard}>
		<div>{myData.name}<span className="cost">${myData.cost.coins}</span></div>
		<div className={classes.mediumCardImg}><img draggable="false" src={process.env.PUBLIC_URL + myData.imageUrl} /></div> </Paper>
}

export function DraggableCardPile(props) {
	let [state, setState] = useState({
		// Whether or not we are currently dragging.
		dragging: false,
		// Current transform x and y.
		x: 0,
		y: 0,

	});
	
	const onDragStart = (e, coreData) => {
		setState({ ...state, x:e.pageX, y:e.pageY, dragging: true, dragged: true });
	};

	const onDrag = (e, coreData) => {
		if (!state.dragging) return false;
		setState({ ...state, x: state.x + coreData.deltaX, y: state.y + coreData.deltaY });
	}

	const onDragStop = (e, coreData) => {
		if (!state.dragging) return false;

		setState({ ...state, x:0, y:0,  dragging: false });
	}
	let nodeCopy = null;

	let style = ({ position:"absolute",top: `${state.y}px`, left: `${state.x}px` });
	if (state.dragging)
		style = Object.assign(style, { backgroundColor: "red" });
	
	if (state.dragging)
	{
		nodeCopy = <Card {...props} style={{ ...style }} />
	}

	return <>
	{nodeCopy}
	<DraggableCore onStart={onDragStart} onDrag={onDrag} onStop={onDragStop}>
		<Card {...props} />
	</DraggableCore>
	</>
}