import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(10),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function Login(props) {
    const classes = useStyles();
    const [username, setUsername] = React.useState();
    const handleChange = (evt => setUsername(evt.target.value));
    const handleSubmit = (evt => {evt.preventDefault(); props.onLoginAttempt(username)});
    return (
        <Container component="main" maxWidth="sm" className={classes.paper}>
            <CssBaseline />
            <Typography component="h1">
                Reign
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="login"
                    label="Choose a username"
                    autoComplete="name"
                    onChange={handleChange}
                    autoFocus />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                >Login</Button>
            </form>
        </Container>
    )
}