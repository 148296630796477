import React from 'react';
import { Typography, List, Grid, Button, Paper, Divider, Dialog } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Draggable from 'react-draggable';

import ListItem from '@material-ui/core/ListItem';


import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

export function SuggestedGameDialog({ open, handleClose, handleAccept, initiator, players }) {
    const paperComponent = props => <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
    </Draggable>;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            disableBackdropClick
            PaperComponent={paperComponent}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                New Game Request
        </DialogTitle>
            <DialogContent>
                <DialogContentText >
                    {initiator} has suggested a game with
                </DialogContentText>
                <List>
                    {players.map(player => <ListItem key={player}>{player}</ListItem>)}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
          </Button>
                <Button autoFocus onClick={handleAccept} color="primary">
                    Accept
          </Button>
            </DialogActions>
        </Dialog>
    )
}

export function NewGameButton({ clickHandler }) {
    return (
        <Paper>
            <Button variant='contained' size='large' color='secondary' onClick={clickHandler} fullWidth>
                <Typography align='center'>+ <br />New Game</Typography>
            </Button>
        </Paper>
    )
}

export function GameList({ games, onNewGame, lobbyOccupants }) {
    const classes = makeStyles({
        root: {
            margin: '2em'
        }
    })();
    let items = [];
    _.forOwn(games, (players, name) => {
        items.push(<Grid item key={name}>
            <Paper>
                <Typography variant="h5">{name}</Typography>
                {players.map(player => <Typography key={player}>{player}</Typography>)}
            </Paper>
        </Grid>);
    });

    return <><Grid container direction="column">
        <Grid item><NewGameButton clickHandler={onNewGame} /></Grid>
        {items.length > 0 &&
            <Grid item><Typography align="center"> Games:</Typography><Divider /></Grid>}
        {items}</Grid>
        <Divider classes={{ root: classes.root }} />
        <Grid container direction="column">
            <Grid item><Typography>Lobby</Typography></Grid>
            {lobbyOccupants.map(playername => <Grid item key={playername}>{playername}</Grid>)}
        </Grid></>
}