import React, { Component } from 'react';
import DominionGameDisplay from './dominionGameDisplay';

const defaultState = {
    wsStatus: "Not Connected",
    playerNames: [],
    playerIds: [],
    msgs: []
};

export default class DominionGame extends Component {
    constructor(props) {
        super(props);
        const { username, gameId, gameSocketAddr } = props;
        this.socket = new WebSocket(gameSocketAddr);
        this.state = { ...defaultState };
        this.reply = data => { this.socket.send(JSON.stringify(data)); console.log(data) };
        this.socket.onmessage = evt => {
            const msg = JSON.parse(evt.data);
            console.log(msg);
            if (msg.hasOwnProperty('chat'))
                this.setState(prevState => ({ msgs: prevState.msgs.concat([{ username: msg.username, text: msg.chat }]) }));
            else if ('request' in msg)
                this.requestHandler(msg)
            else
                this.msgHandler(msg);
        }
        this.socket.onopen = evt => this.setState({ wsStatus: "Connected" })
    }

    requestHandler(msg) {
        switch (msg.request) {
            case 'login':
                this.reply({
                    response: "login",
                    username: this.props.username,
                    game_id: this.props.gameId,
                    version: 2
                });
                break;
            default:
        }
    }

    msgHandler(msg) {
        for (let bundle of msg) {
            const message = bundle.message;
            switch (bundle.type) {
                case 'players':
                    this.setState({
                        playerNames: message.map(p => p.name),
                        playerIds: message.map(p => p.id)
                    });
                    break;
                case 'cardlist':
                    this.setState({ fullCardlist: message });
                    break;
                default:
                    break;
            }
        }
    }

    sendChatMsg(msg) {
        if (msg)
            this.socket.send(JSON.stringify({ username: this.props.username, chat: msg }));
    }

    render() {
        this.sendChatMsg = this.sendChatMsg.bind(this);
        return <DominionGameDisplay
            wsStatus={this.state.wsStatus}
            playerNames={this.state.playerNames}
            msgs={this.state.msgs}
            sendMessage={this.sendChatMsg}
        />
    }

}