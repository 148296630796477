import React from 'react';
import { Drawer, Typography, Box, Button } from '@material-ui/core';

import DoneIcon from '@material-ui/icons/Done';
import CircularProgress from '@material-ui/core/CircularProgress';


export default function SuggestedGameDrawer({ gameId, handleClose, allPlayers, greenPlayers }) {
    let statuses = [];
    for (let player of allPlayers)
        statuses.push(<Box key={player}><Typography component='div'>{greenPlayers.includes(player) ? <DoneIcon color="primary" fontSize='large' /> : <CircularProgress color="secondary" />}...{player}</Typography></Box>);
    return <Drawer anchor='right' open={gameId !== null} onClose={handleClose} disableBackdropClick>
        <Box p={3} minWidth='30em' display="inline">
            <Typography component='span'>Waiting for all players to join game <h2 style={{display:'inline'}}>{gameId}</h2>...</Typography>
            {statuses}
        </Box><Button variant='contained' onClick={handleClose}>Cancel Game</Button></Drawer>
}