import React, { Component } from 'react';
import { Drawer, Typography, FormControlLabel, Checkbox, Box, Paper, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
    playerBox: {
        margin: theme.spacing(1),
        width: theme.spacing(16),
        height: theme.spacing(16),
        display: 'flex',
        justifyContent: 'center'
    }
}));

export default class NewGameDrawer extends Component {

    constructor(props) {
        super(props);
        this.state = props.availablePlayers.reduce(
            (acc, player) => { acc['player' + player] = false; return acc }, {});
    }

    handleChange = (event) => {
        const key = 'player' + event.target.name;
        this.setState({[key]: event.target.checked});
    };

    suggestGame = (optsRef) => {
        const checkboxState = _.map(_.keys(_.pickBy(this.state)), p => _.trimStart(p, 'player')); //state:true for state['playerBob']
        this.props.onSuggest(checkboxState, optsRef.current.value);
    }

    render() {
        const colors = [{ active: 'green', inactive: 'darkgreen' }, { active: 'red', inactive: 'darkred' }, { active: 'blue', inactive: 'darkblue' }];
        let color_idx = 0;
        let manualOptions = React.createRef();

        return <Drawer anchor='right' open={this.props.open} onClose={this.props.onClose} >

            <Box p={3} minWidth='30em' display="flex">
                {this.props.availablePlayers.map(player =>
                    <PlayerBox
                        key={player}
                        player={player}
                        bgcolor={colors[color_idx++ % colors.length].inactive}
                        isChecked={this.state['player' + player]}
                        onChange={e => this.handleChange(e)} />)}
            </Box>
            <Button variant='contained' size='large' color='secondary' onClick={e => this.suggestGame(manualOptions)}><Typography align='center'>Suggest This Game</Typography></Button>
            <TextField id="additionalOptions" name="additionalOptions" label="Manually add game options" inputRef={manualOptions}></TextField>
        </Drawer>
    }
}

function PlayerBox({ player, isChecked, onChange, bgcolor }) {
    const classes = useStyles();
    return <Paper elevation={3} square className={classes.playerBox}>
        <Box display="flex" justifyContent="center" alignContent="center" bgcolor={bgcolor} width={1}>
            <FormControlLabel key={player} labelPlacement="bottom" style={{ color: "white" }} control={
                <Checkbox checked={isChecked || false} onChange={onChange} name={player} />}
                label={player} />
        </Box>
    </Paper>
}