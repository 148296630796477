import React from 'react';
import TextField from '@material-ui/core/TextField';
import PublishIcon from '@material-ui/icons/Publish';
import { Typography, Box, Button, List, ListItem } from '@material-ui/core';

export default function ChatRoom({ msgs, sendMessage }) {
    let items = msgs.map((msg, i) => {
        if (msg.username)
            return <ListItem key={i}><Typography color="textPrimary" mr={5}><b>{msg.username}:</b></Typography><Typography>{msg.text}</Typography></ListItem>
        else
            return <ListItem key={i}><Typography color="primary"><b>{msg.text}</b></Typography></ListItem>
    });
    let chatbox = React.createRef();
    const handleSubmit = evt => {
        evt.preventDefault();
        sendMessage(chatbox.current.value);
        chatbox.current.value = "";
    };

    return (<>
        <Box style={{ 'minHeight': '11em' }}>
            <List>
                {items}
            </List>
        </Box>
        <form onSubmit={handleSubmit} autoComplete='off'>
            <TextField id="outlined-basic"
                label="Chat"
                variant="outlined"
                inputRef={chatbox}
                fullWidth
                autoFocus
                InputProps={{
                    endAdornment: <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        startIcon={<PublishIcon />}>
                        send</Button>
                }} /></form></>

    )
}